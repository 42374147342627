<template>
  <div>
    <v-alert
      v-if="profileMissing"
      type="error"
    >
      Your email address must be confirmed before your account is activated. Please check your email for the confirmation link.
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
      profileMissing: state => state.auth.profileMissing,
    }),
  },
  watch: {
    profile: function (newValue) {
      // Send the user home if the profile is created.
      if (newValue) {
        return this.$router.push({name: 'Home'})
      }
    },
  },
  created: function() {
    this.$store.dispatch('auth/getProfile')
  },
}
</script>
